// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1)
      // Tablet: (u.indexOf('windows') != -1)
      || u.indexOf('ipad') != -1
      || (u.indexOf('macintosh') > -1 && 'ontouchend' in document)
      || (u.indexOf('android') != -1 && u.indexOf('mobile') == -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1)
      || u.indexOf('kindle') != -1
      || u.indexOf('silk') != -1
      || u.indexOf('playbook') != -1,
    Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1)
      || u.indexOf('iphone') != -1
      || u.indexOf('ipod') != -1
      || (u.indexOf('android') != -1 && u.indexOf('mobile') != -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1)
      || u.indexOf('blackberry') != -1
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 600;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  setScroll();
  setHeader();
  // killClick()
  if (!$('#wrapper').hasClass('home')) {}
  // ///////////
  if (_ua.Tablet || _ua.Mobile) {
    $('#wrapper').addClass('setTablet');
  }
  // ///////////
  if (!_ua.Mobile) {}
});

$(window).on('load', function () {
  heightLineGroup();
  svg4everybody();
  judgeWinSize();
  setMainMenu();
  setAcc();
  setTab();
  setLoaded();
  setScrollTop();
  setTimeout(function () {
    setWay();
  }, 500);
  // setScrollTop()
  // setTextile()
  if ($('#wrapper').hasClass('home')) {
    setMainVis();
    if (!_ua.Mobile && !_ua.Tablet) {
      setPal();
    }
  }
  if (!_ua.Mobile && !_ua.Tablet) {
    // setPal()
  }
});

// ///////TOPボタン
function setScrollTop () {
  var baseHeight = 10;
  var footH = $('footer').height();
  var timer = false;
  fixedToTop();

  $(window).scroll(function () {
    fixedToTop();
  });

  function fixedToTop () {
    var scrollHeight = $(document).height() - footH;
    if ($(this).scrollTop() <= baseHeight) {
      $('#toTop').removeClass('active');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#toTop').addClass('active');
    }
    if ($(window).height() + $(this).scrollTop() >= scrollHeight) {
      $('#toTop').addClass('footPos');
    }else {
      $('#toTop').removeClass('footPos');
    }
  }
}

// /////TOPアニメーション
function setMainVis () {
  $('#kvVideoSp video').get(0).play();
  setTimeout(function () {
    $('#wrapper').addClass('logo2nd');
  }, 300);
  setTimeout(function () {
    $('#wrapper').addClass('delayLoaded');
    $('#kvVideo video').get(0).play();
  }, 2000);
  setTimeout(function () {
    $('#wrapper').addClass('logoOut');
  }, 15000);

// $('.playtest').on('click', function () {
//   $('#kvVideoSp video').get(0).play()
// })
}

// /////フォーム制御
function setForm () {
  jQuery('.wpcf7-tel').attr('pattern', '\\d*');
  jQuery('.wpcf7-tel').attr('onkeyup', 'if(event.keyCode==9||event.keyCode==16) return; this.value=this.value.replace(/[^0-9]+/i,"")');

  $('input[name="acceptance-form"]').change(function () {
    var prop = $(this).prop('checked');
    if (prop) {
      $('#confirmBtn').removeClass('def');
    } else {
      $('#confirmBtn').addClass('def');
    }
  });

  $('#js-count').keyup(function () {
    var count = $(this).val().length;
    $('.show-count').text(count);
  });
}

// /////郵便番号検索
function setPostal () {
  $('#zip').jpostal({
    postcode: [
      '#zip1',
      '#zip2'
    ],
    address: {
      '#pref': '%3',
      '#address1': '%4%5'
    }
  });
}

// ////////////////////////////タブ
function setTab () {
  $('.tagNav a').on('click', function () {
    if (!$(this).parent().hasClass('active')) {
      var target = '#' + $(this).attr('class');
      $(this).parents('.tagNav').find('li').removeClass('active');
      $(this).parent().addClass('active');
      $(this).parents('.tagArchive').find('.tabSection').hide().removeClass('active');
      $(this).parents('.tagArchive').find(target).fadeIn().addClass('active');
    }
  });

  $('.ginouNav a').on('click', function () {
    if (!$(this).parent().hasClass('active')) {
      var target = '#' + $(this).attr('class');
      $(this).parents('.ginouNav').find('li').removeClass('active');
      $(this).parent().addClass('active');
      $(this).parents('#container').find('.ginouTab').hide().removeClass('active');
      $(this).parents('#container').find(target).fadeIn().addClass('active');
    }
  });
}

// ////////////////////////////アコーディオン
function setAcc () {
  $('.accSwitch').on('click', function () {
    if (!$(this).hasClass('active')) {
      $(this).addClass('active');
      $(this).next().slideDown('fast');
    }else {
      $(this).removeClass('active');
      $(this).next().slideUp('fast');
    }
  });
}

// ///////////////////////////パララックス
function setPal () {
  var rellax = new Rellax('.pal');
}

// /////////////////////////////無限スライダー
function setInfinite () {
  $('.infiniteslide.style_01').infiniteslide({
    pauseonhover: false,
    speed: 50
  });
}

// //////////////////////////メインメニュー
var current_scrollY;
function setMainMenu () {
  $('#burgerMenu').on('click', function () {
    if (!$(this).hasClass('active')) {
      openFnc();
    }else {
      closeFnc();
    }
  });

  $('#clickBlocker').on('click', function () {
    closeFnc();
  });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}

function openFnc () {
  current_scrollY = $(window).scrollTop();
  $('#burgerMenu').addClass('active');
  $('#wrapper').addClass('menuOpen');
  $('#outerMenu').css('top', -(current_scrollY));
  setTimeout(function () {
    $('html, body').prop({
      scrollTop: 0
    });
  }, 100);
}

function closeFnc () {
  $('#burgerMenu').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  $('html, body').prop({
    scrollTop: current_scrollY
  });
}

// /////////////////インスタ
function setDummyInstagram () {
  if (!_ua.Mobile) {
    var mrg = 20;
    var sort = 8;
  }else {
    var mrg = 15;
    var sort = 4;
  }
  var target = '.carouselStyle_01';
  $(target).each(function () {
    var owl = $(this).find('.screen');
    var baseCount = $(this).find('.screen>.carouselItem').length + 1;
    if (baseCount > 2) {
      owl.owlCarousel({
        loop: true,
        margin: mrg,
        items: 1,
        dots: false,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        URLhashListener: false
      });
    } else {
      owl.removeClass('owl-carousel');
    }
  });
}
// /////
function setInstagram () {
  var limitNum = 32;
  var setUrl;
  if (!_ua.Mobile) {
    var mrg = 20;
    var sort = 8;
  }else {
    var mrg = 15;
    var sort = 4;
  }
  $.ajax({
    type: 'GET',
    // url: 'https://graph.facebook.com/v9.0/17841427049785609?fields=name%2Cmedia.limit(' + limitNum + ')%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EAA9VdZBqZBUl0BAE9ZC7Ob0DRsHb3OPrkPq6A0lxGnUi95W5HIxfb7LsGqZBVxOpholIFDGugqnKskZAVZCzkOtCy8wAIil1uP1ekiquiAjfU093Bhg81V2CPxDbKZCoVcl3ffZAc6r55Xr7ZBu3gHRg9uBcSEptb307nJVDYUWx3krXQO0RIZCzvV',
    url: 'https://graph.facebook.com/v3.0/102261269898329?fields=name%2Cmedia.limit(' + limitNum + ')%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EAACNH0QiNL4BAFYZCgET0FcBBF39X0WOSY9ntfCszByXqLcjinBayzV00evbnukutdswAhTLH2JcSu6pzvAnRk3z2VK58tiAXswCV8EQGjvgQ1xt6WNGBLhfszCaWxDTUGlvLd4JNqJjxqdbYRryoNn95ZAiFHVuNRgVfeRLW97sGPJNID',
    dataType: 'json',
    success: function (json) {
      var html = '';
      var insta = json.media.data;
      for (var i = 0; i < insta.length; i++) {
        var htmlHead;
        var htmlSep;
        var count = i + 1;
        if (count == 1) {
          htmlHead = '<div class="carouselItem">';
        }else {
          htmlHead = '';
        }
        if (count == insta.length) {
          htmlSep = '</div>';
        }else {
          if (count % sort == 0) {
            htmlSep = '</div><div class="carouselItem">';
          }else {
            htmlSep = '';
          }
        }
        if (insta[i].thumbnail_url) {
          imgPath = insta[i].thumbnail_url;
        }else {
          imgPath = insta[i].media_url;
        }
        html += htmlHead + '<div><span><a href="' + insta[i].permalink + '" target="_blank"><img src="' + imgPath + '"></a></span></div>' + htmlSep;
      }
      $('#instafeed').append(html);

      // /////////////////カルーセル
      var target = '.carouselStyle_01';
      $(target).each(function () {
        var owl = $(this).find('.screen');
        var baseCount = $(this).find('.screen>.carouselItem').length + 1;
        if (baseCount > 2) {
          // owl.addClass('owlCarousel')
          owl.owlCarousel({
            loop: true,
            // autoWidth: true,
            margin: mrg,
            // slideSpeed: 3000,
            // paginationSpeed: 1000,
            items: 1,
            // smartSpeed: 1000,
            dots: false,
            nav: false,
            // stagePadding: 200,
            // animateOut: 'fadeOut',
            mouseDrag: true,
            touchDrag: true,
            URLhashListener: false,
          // autoplay: true,
          // autoplayTimeout: 5000,
          // autoplayHoverPause: true,
          // slideBy: 1,
          // startPosition: 'URLHash'
          // slideBy:5,
          });
        } else {
          owl.removeClass('owl-carousel');
        }
        owl.on('changed.owl.carousel', function (e) {
          // owl.trigger('stop.owl.autoplay')
          // owl.trigger('play.owl.autoplay')
        });
      });
    },
    error: function () {}
  });
}

// ///////ヘッダー制御
function setHeader () {
  var timer = false;
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('header').removeClass('fixedHeader');
    }, 10);
  });

  fixedHeader();

  $(window).scroll(function () {
    fixedHeader();
  });

  function fixedHeader () {
    if ($('#wrapper').hasClass('home')) {
      h = $('#mainVis').height();
    }else {
      h = $('header').height();
    }
    baseHeight = h;
    if ($(this).scrollTop() <= baseHeight) {
      $('#wrapper').removeClass('fixedHeader');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#wrapper').addClass('fixedHeader');
    }
  }
}

// ////////////ウィンドウサイズを判別
function judgeWinSize () {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// //////////////////////////////ロード完了
function setLoaded () {
  $('#loading').addClass('loaded');
  $('#wrapper').addClass('loaded');
  $('body,html').animate({
    scrollTop: 0
  }, 0, 'swing');
}

// ///////waypoint
function setWay () {
  $('.way,.alphaWay,.scaleWay,.setAnimation,#bgTx>div,.nullWay').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.shuffle').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
      // var container = $(this.element)
      activePoint.shuffleLetters();
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.scaleway,.nullWay,.ttlStyle_05,.fade_01,.fade_02').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });
}

// /////////////////////////////スムーススクロール
function setScroll () {
  var headerHight = $('header').height() + 100;

  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top - headerHight;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    $.when(
      $('html, body').animate({
        scrollTop: position
      }, 400, 'swing'),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top - headerHight;
      if (diff === position) {
      } else {
        $('html, body').animate({
          scrollTop: diff
        }, 10, 'swing');
      }
    });
  });
}

function startScroll () {
  // var headerHight = 0
  var headerHight = $('header').height() + 100;
  var href = $(location).attr('hash');
  var target = $(href == '#' || href == '' ? 'html' : href);
  var position = target.offset().top - headerHight;

  $.when(
    $('html, body').animate({
      scrollTop: position
    }, 400, 'swing')
  ).done(function () {
    var diff = target.offset().top - headerHight;
    if (diff === position) {
    } else {
      $('html, body').animate({
        scrollTop: diff
      }, 10, 'swing');
    }
  });
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup () {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('.setH_01 .headTtl')
    // setAutoHeight('.setH_01 h2')
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight (target, count) {
  $(target).tile(count);
}

function startResize () {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
